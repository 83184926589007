var page_loaded = 1;
global.page_loaded = page_loaded;

// var smInit = require('scrollmagic'),
// 	animation = require('../../../node_modules/scrollmagic/scrollmagic/minified/plugins/animation.gsap.min.js'),
// 	tmInit = require('../../../node_modules/gsap/TimelineMax.js')
	
page = {
	load: function(){
		this.newsScroll();
	},
	newsScroll: function(){
		if ($('#news_trigger').length) {
			var controller = new smInit.Controller();
			var tl = new tmInit();
			var tween = tl.staggerFromTo(".homePost", 1.5, {top: 240, opacity: .2}, {top: -80, opacity:1, ease: Linear.easeNone}, .8);

			// build scene
			var scene = new smInit.Scene({triggerElement: "#news_trigger",  duration: $(window).height()}).setTween(tween).addTo(controller);
		}
	}
}


$(window).on('load', function(){
	page.load();
})